import React from 'react';
import { useAuth } from "../AuthProvider";
import DatumLogo from "../datum-logo.svg"

const Navbar = () => {
  const auth = useAuth();
  const handleLogout = () => {
    auth.logOut();
  }
  return (
    <nav className="h-1/5 bg-gray-800 p-1">
      <div className="flex justify-between items-center">
          <div className="text-white text-lg font-bold flex-grow text-center">
          <img src={DatumLogo}/>
          </div>
        <button onClick={handleLogout} className="text-white text-lg font-bold btn float-end">
          Logout
        </button>
      </div>
    </nav>
  );
};

export default Navbar;