import React, { useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import TagCard from './TagCard';
import PIM360 from '../datum360/pim360';
import CLS360 from '../datum360/cls360';
import { useLocation } from 'react-router-dom';
import { submitRefreshToken, checkTokenExpiry } from '../AuthProvider';
export default ({setTags, tags, zoomToTag, zoomLevel}) => {

    const [liveviews, setLiveviews] = React.useState([]);
    const [filter, setFilter] = React.useState("")
    const [loading, setLoading] = React.useState(false);

    const navigate = useNavigate();

    const liveviewSelect = async (e) => {
        setTags([])
        setLoading(true);
        if(checkTokenExpiry){
          await submitRefreshToken();
        }
        const selectedLiveviewName = e.target.value;
        const liveviewDetails = liveviews.find((view) => view.key === selectedLiveviewName);
        const pim = localStorage.getItem("pim");
        const cls = localStorage.getItem("cls");
        const token = localStorage.getItem("site");
        let liveviewResults = await PIM360.getLiveviewResult(pim, liveviewDetails.value, token, 0, 1);
        const liveview = await PIM360.getLiveviewDetails(pim, liveviewDetails.value, token);
        const tagProperties = [];
        const tags = [];
        const snapshotDetails = await PIM360.getSnapshotDetails(pim, token);
        const classLibraryHdl = snapshotDetails.hdl;
        const latitudeAttributeName = localStorage.getItem("Latitude");
        const longitudeAttributeName = localStorage.getItem("Longitude");

        const firstTag = liveviewResults.data[0];
        for(const prop of liveview.Data.fields){
          try{
            const attributeDetails = await CLS360.getClassByHdl(prop, classLibraryHdl, cls, token);
            if(attributeDetails){
              tagProperties.push({
                "Hdl": prop,
                "Name": firstTag[prop].name
              });
            }
          }
          catch(err){
            continue;
          }
        }

        let isFirst = true;
        let skip = 0;
        do{
          liveviewResults = await PIM360.getLiveviewResult(pim, liveviewDetails.value, token, skip, 200);
          for(const tag of liveviewResults.data){
            const newTag = {
              Hdl: tag.hdl,
              objId: tag.objId,
              setVisible: null
            }
            for(const prop of tagProperties){
              if(tag[prop.Hdl]){
                newTag[prop.Name] = tag[prop.Hdl].value
              }
            }

            tags.push(newTag);

            if(isFirst && newTag[`${latitudeAttributeName}`] && newTag[`${longitudeAttributeName}`]){
              const tagPosition = {
                lat: parseFloat(newTag[`${latitudeAttributeName}`]),
                lng: parseFloat(newTag[`${longitudeAttributeName}`])
              }
              zoomToTag(tagPosition);
              zoomLevel(9.5);
              isFirst = false;
            }
          }
          skip += 200;
        }
        while(liveviewResults.hasmore)
      
        setLoading(false)
        setTags(tags);
      }

    useEffect(() => {
        async function getLiveviews(){
          try{
            setLiveviews([]);
            if(checkTokenExpiry){
              await submitRefreshToken();
            }
            const token = localStorage.getItem("site");
      
            const pim = localStorage.getItem("pim");
            const views = await PIM360.getLiveviews(pim, token);
      
            const liveviews = []
            views.forEach((view) => {
              liveviews.push({
                key: view.Name,
                value: view.Hdl
              })
            })
      
            setLiveviews([
              {key: "Select a liveview...", value: "none"},
              ...liveviews
            ])
          }
          catch(err){
            navigate('/')
          }
    
        }
        getLiveviews();
      }, [])

    const location = useLocation();
    const hideComponent = location.pathname === '/liveViewToTag';

    return (
        <>
          <div>
              <h2 className="text-2xl font-bold">Details</h2>
              <div style={{display: hideComponent ? 'none' : 'block'}}>
                <p>Select a Liveview</p>
                <select className='my-4' onChange={liveviewSelect}>
                {
                    liveviews.map((view) => {
                    return (
                        <option key={view.key} value={view.hdl}>
                        {view.key}
                        </option>
                    )
                    })
                }
                </select>
                <input style={{display: 'block'}} value={filter} onChange={event => setFilter(event.target.value)} placeholder='Search for tags'></input>
              </div>
              <div className='h-[75vh] overflow-y-auto'>
              <svg style={{display: loading ? 'block' : 'none'}} aria-hidden="true" class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-emerald-300" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
              </svg>
                <ul className='gap-5 overflow-y-auto'>
                  {
                    tags.filter(tag => tag.objId.toLowerCase().includes(filter.toLowerCase()) || filter === '')
                    .map(tag => <TagCard tag={tag} zoomToTag={hideComponent ? ()=>{} : zoomToTag} zoomLevel={hideComponent ? ()=>{} : zoomLevel}/>)
                  }
                </ul>
              </div>
            </div>
        </>
    )
}