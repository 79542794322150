const getLiveviews = async (systemName, token) => {
    const response = await fetch(`https://${systemName}.pim360.io/api/customviews?type=LIVE_VIEW`, {
        method: "GET",
        headers: {
            "authorization": `Bearer ${token}`
        }
    })
    
    const views = await response.json();

    return views;
}

const getLiveviewResult = async (systemName, liveviewHdl, token, skip = 0, limit = 200) => {
    const response = await fetch(`https://${systemName}.pim360.io/api/queryresults/views/${liveviewHdl}?skip=${skip}&limit=${limit}`, {
        method: "GET",
        headers: {
        "authorization": `Bearer ${token}`
        }
    })
    
    const liveviewResults = await response.json();

    return liveviewResults
}

const getLiveviewDetails = async(systemName, liveviewHdl, token) => {
    const response = await fetch(`https://${systemName}.pim360.io/api/customviews/${liveviewHdl}`, {
        method: "GET",
        headers: {
        "authorization": `Bearer ${token}`
        }
    })
    
    const liveviewDetails = await response.json();

    return liveviewDetails
}

const getSnapshotDetails = async (systemName, token) => {
    const response = await fetch(`https://${systemName}.pim360.io/api/snapshotversion`, {
        method: "GET",
        headers: {
        "authorization": `Bearer ${token}`
        }
    })

    const snapshot = await response.json();

    return snapshot;
}

const getTagByHdl = async(systemName, token, tagHdl) => {
    const response = await fetch(`https://${systemName}.pim360.io/api/objects/TAGGED_ITEM/${tagHdl}`, {
        method: "GET",
        headers: {
            "authorization": `Bearer ${token}`
        }
    })

    const tag = await response.json();

    return tag;
}

module.exports = {getLiveviews, getLiveviewResult, getSnapshotDetails, getTagByHdl, getLiveviewDetails}