import {Marker, useMarkerRef, InfoWindow} from '@vis.gl/react-google-maps';
import React from 'react'

export default ({tag, latHdl, lonHdl, active}) => {
    const [markerRef, marker] = useMarkerRef();
  
    const [infoWindowShown, setInfoWindowShown] = React.useState(false);
  
    const handleMarkerClick = React.useCallback(
      () => setInfoWindowShown(isShown => !isShown),
      []
    );
  
    const handleClose = React.useCallback(() => setInfoWindowShown(false), []);
    const latitudeAttributeName = localStorage.getItem("Latitude");
    const longitudeAttributeName = localStorage.getItem("Longitude");
    const createPosition = (tag) => {
      const lat = tag[latitudeAttributeName];

      const long = tag[longitudeAttributeName];
      tag.setVisible = () => {
        handleMarkerClick();
      }
      return {lat: Number.parseFloat(lat), lng: Number.parseFloat(long)};
    }
  
    return (
      <>
        <Marker
          ref={markerRef}
          position={createPosition(tag)}
          onClick={handleMarkerClick}
        />
  
        {infoWindowShown && (
          <InfoWindow anchor={marker} onClose={handleClose}>
            <a href={`https://${localStorage.getItem("pim")}.pim360.io/object/TAGGED_ITEM/${tag.Hdl}`} target='_blank' className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" >{tag.objId}</a>
            {
              Object.keys(tag).map((property) => {
                if(property != "Hdl" && property != "objId" && tag[property] && typeof(tag[property]) != "function"){
                  return <>
                    <p><b>{property}</b>: {tag[property]}</p>
                  </>
                }
              })
            }
          </InfoWindow>
        )}
      </>
    );
  };