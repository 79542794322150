const getAttributeByName = async (attributeName, attributeType, domainHdl, systemName, token) => {
    const filterString = `Name eq [${attributeName}] and [Object Type] eq [${attributeType}]`;
    const encodedFilter = encodeURIComponent(filterString);
    const url = `https://${systemName}.cls360.io/api/domains/${domainHdl}/classes?filter=${encodedFilter}`;

    const response = await getRequest(url, token);
    return response.json();
};

const getClassByHdl = async (attributeHdl, domainHdl, systemName, token) => {
    const url = `https://${systemName}.cls360.io/api/domains/${domainHdl}/classes/${attributeHdl}`;
    const response = await getRequest(url, token);
    if(response.ok){
        return response.json();
    }
    else{
        return null;
    }
}

const getRequest = async (url, token) => {
    const response = await fetch(url, {
        method: "GET",
        headers: {
            "authorization": `Bearer ${token}`
        }
    });

    return response;
}

module.exports = {getAttributeByName, getClassByHdl}