import React from 'react'
export default ({tag, zoomToTag, zoomLevel}) => {

    const handleCardClick = (e) => {
      const latitudeAttributeName = localStorage.getItem("Latitude");
      const longitudeAttributeName = localStorage.getItem("Longitude");
      if(tag[`${latitudeAttributeName}`] && tag[`${longitudeAttributeName}`]){
        const position = {lat: parseFloat(tag[`${latitudeAttributeName}`]), lng: parseFloat(tag[`${longitudeAttributeName}`])};
        zoomToTag(position);
        zoomLevel(20);
        tag.setVisible()
      }
    }
    
    return (
        <>
          <li>
            <div onClick={handleCardClick} className='border-b-2 border-slate-300'>
              <div class="p-5">
                  <h2 class="text-xl font-semibold mb-3">{tag.objId}</h2>
              </div>
            </div>
          </li>
        </>
    )
}