import { useState } from "react";
import { useAuth } from "./AuthProvider";
import DatumLogo from "./datum-logo.svg"
import React from 'react'
import './css/Login.css';

const Login = () => {

    const [loginProviders] = React.useState([
        {
            SystemName: "or",
            pim: "or",
            cls: "or",
            clientId: "apiuser",
            clientSecret: "x6rbCUPwQC4Mn-_CsGWx",
            scope: "or-pim360 or-cls360",
            Latitude: "Approved Latitude",
            Longitude: "Approved Longitude"
        },
        {
            SystemName: "dap-demo",
            pim: "dap-demo",
            cls: "dap-demo",
            clientId: "serviceaccount",
            clientSecret: "C>T,4MA#e[9BRgs>om@h",
            scope: "dap-demo-pim360 dap-demo-cls360",
            Latitude: "latitude",
            Longitude: "longitude"
        },
        {
            SystemName: "datum360-cdp",
            pim: "datum360-cdp",
            cls: "datum360-cdp",
            clientId: "datum360service",
            clientSecret: "VVGWewVA3kE>e7654=!1",
            scope: "datum360-cdp-pim360 datum360-cdp-cls360",
            Latitude: "latitude",
            Longitude: "longitude"
        }
    ])
  const auth = useAuth();
  const handleSubmitEvent = (url, clientId, clientSecret, scope, SystemName, latitude,longitude, pim, cls) => {
      auth.loginAction({
        url: url,
        clientId: clientId,
        clientSecret: clientSecret,
        scope: scope,
        systemName: SystemName,
        pim: pim,
        cls: cls
      });

      localStorage.setItem("Latitude", latitude);
      localStorage.setItem("Longitude", longitude);
  };

  return (
    <div className="flex items-center justify-center min-h-screen">
        <div class="container">
            <div class="login-box bg-white p-6 rounded-lg shadow-lg">
                <h2  class="text-center text-lg font-bold">Sign in</h2>
                <ul>
                {
                    loginProviders.map((provider) => {
                        return (
                            <li className="p-1">
                                <button type="submit" onClick={() => handleSubmitEvent(provider.url, provider.clientId, provider.clientSecret, provider.scope, provider.SystemName, provider.Latitude, provider.Longitude, provider.pim, provider.cls)} class="text-center text-lg font-bold btn">Sign in to {provider.SystemName}</button>
                            </li>
                        )
                    })
                }
                </ul>
            </div>
            <div class="bottom-logo">
                <img src={DatumLogo}/>
            </div>
        </div>
    </div>
  );
};

export default Login;