import { useContext, createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem("site") || "");
  const navigate = useNavigate();
  const loginAction = async (data) => {
    localStorage.setItem("systemName", data.systemName);
    localStorage.setItem("pim", data.pim);
    localStorage.setItem("cls", data.cls);
    localStorage.setItem("clientId", data.clientId);
    localStorage.setItem("clientSecret", data.clientSecret);
    const redirectUrl = encodeURIComponent(`${window.location.protocol}//${window.location.host}/login`);
    window.location.replace(`https://${data.systemName}.acl360.io/oauth2/authorize?response_type=code&client_id=${data.clientId}&redirect_uri=${redirectUrl}&scope=${data.scope}`)
  };

  const logOut = () => {
    setToken("");
    localStorage.removeItem("site");
    navigate("/login");
  };

  const handleOAuthRedirect = async (code) => {
    const systemName = localStorage.getItem("systemName");
    const clientId = localStorage.getItem("clientId");
    const clientSecret = localStorage.getItem("clientSecret");
    const redirectUrl = `${window.location.protocol}//${window.location.host}/login`;
    const response = await fetch(`https://${systemName}.acl360.io/oauth2/token`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          grant_type: "authorization_code",
          code: code,
          redirect_uri: redirectUrl,
          client_id: clientId,
          client_secret: clientSecret
        }),
      });

      const data = await response.json();
      if (data.access_token) {
        setToken(data.access_token);
        localStorage.setItem("site", data.access_token);
        localStorage.setItem("refresh", data.refresh_token);
        const decoded = jwtDecode(data.access_token);
        localStorage.setItem("tokenExpires", decoded.exp)
        navigate("/dashboard");
        console.log(data.access_token);
      }
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    if (code) {
      handleOAuthRedirect(code);
    }
  }, []);

  return (
    <AuthContext.Provider value={{ token, loginAction, logOut }}>
      {children}
    </AuthContext.Provider>
  );

};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};

export const checkTokenExpiry = () => {
  const expiryTimestamp = localStorage.getItem("tokenExpires");
  //get current timestamp in seconds
  const currentTime = Math.floor(Date.now / 1000);

  //if token will expire in less than 5 minutes, return true
  if((expiryTimestamp - 300) < currentTime){
    return true;
  }

  return false;

}

export const submitRefreshToken = async() => {
  const refreshToken = localStorage.getItem("refresh");
  const systemName = localStorage.getItem("systemName");
  const clientId = localStorage.getItem("clientId");
  const clientSecret = localStorage.getItem("clientSecret");
  try{
    const response = await fetch(`https://${systemName}.acl360.io/oauth2/token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams({
        grant_type: "refresh_token",
        refresh_token: refreshToken,
        client_id: clientId,
        client_secret: clientSecret
      }),
    });
    const token = await response.json();

    localStorage.setItem("site", token.access_token);
    localStorage.setItem("refresh", token.refresh_token);
    const decoded = jwtDecode(token.access_token);
    localStorage.setItem("tokenExpires", decoded.exp);
    
  }
  catch(err){
    console.log(err);
  }


}