import React, { useEffect, useState, useCallback } from 'react'
import './css/Dashboard.css'
import Navbar from './components/Navbar';
import {APIProvider, Map} from '@vis.gl/react-google-maps';
import MarkerWithInfoWindow from './components/Marker'
import Panel from './components/Panel'
import PIM360 from './datum360/pim360'
import CLS360 from './datum360/cls360';
const Dashboard = () => {

  const [tags, setTags] = React.useState([]);

  const [latitudeHdl, setLatHdl] = React.useState(null);
  const [longitudeHdl, setLonHdl] = React.useState(null);

  useEffect(() => {

    const getAttributeDetails = async () => {
      const pim = localStorage.getItem("pim");
      const cls = localStorage.getItem("cls");
      const token = localStorage.getItem("site");

      const snapshotDetails = await PIM360.getSnapshotDetails(pim, token);
      const classLibraryHdl = snapshotDetails.hdl;
      const latitudeAttributeName = localStorage.getItem("Latitude");
      const longitudeAttributeName = localStorage.getItem("Longitude");
      let latitudeDetails = await CLS360.getAttributeByName(latitudeAttributeName, "Measure Attribute", classLibraryHdl, cls, token)
      if (latitudeDetails.value[0] === undefined) {
        latitudeDetails = await CLS360.getAttributeByName(latitudeAttributeName, "Information Attribute", classLibraryHdl, cls, token);
      }
      setLatHdl(latitudeDetails.value[0].Hdl);

      let longitudeDetails = await CLS360.getAttributeByName(longitudeAttributeName, "Measure Attribute", classLibraryHdl, cls, token)
      if (longitudeDetails.value[0] === undefined) {
        longitudeDetails = await CLS360.getAttributeByName(longitudeAttributeName, "Information Attribute", classLibraryHdl, cls, token);
      }
      setLonHdl(longitudeDetails.value[0].Hdl);
    }

    getAttributeDetails();
    
  }, [])
  

  const [defaultPosition, setDefaultPosition] = React.useState({lat: 31.9955412, lng: 0.3126146});
  const [defaultZoom, setDefaultZoom] = React.useState(2.5);

  const handleCameraChange = useCallback((ev) =>{ 
    const newPosition = ev.map.center;
    const newZoom = ev.map.zoom;
    setDefaultPosition(newPosition);
    setDefaultZoom(newZoom);
  }
  );

  return  (
    <>
      <Navbar/>
      <div className="flex h-[calc(100vh-5rem)] overflow-hidden">
          <div className="w-2/3 h-full">
                <APIProvider apiKey='AIzaSyC8UKiq6owamokvkMZ6Gr331MIKCrotDEI'>
                <Map center={defaultPosition} zoom={defaultZoom} onCameraChanged={handleCameraChange}>
                  {
                    tags.map((tag) => {
                      return <MarkerWithInfoWindow tag={tag} latHdl={latitudeHdl} lonHdl={longitudeHdl}/>
                    })
                  }
                </Map>
                </APIProvider>
          </div>
          <div className="w-1/3 bg-gray-200 p-4 h-full">
            <Panel setTags={setTags} tags={tags} zoomToTag={setDefaultPosition} zoomLevel={setDefaultZoom} ></Panel>
          </div>
        </div>
      </>
  )
};

export default Dashboard;